<template>
  <div>
    <MainStepper e1="4" />
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="title"
      class="elevation-1"
      loading-text="Loading... Please wait"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-row>
            <v-col cols="6">
              <span class="text-h6 primary--text">AUTHORITY APPROVALS</span>
            </v-col>
            <v-col cols="6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="goToContracts"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
                <span>Skip</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="goToSupplierSelection"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </template>
                <span>Back</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="gotToMyApprovals"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    fab
                  >
                    <v-icon>mdi-clipboard-check-outline</v-icon>
                  </v-btn>
                </template>
                <span>My approval requests</span>
              </v-tooltip>
              <v-text-field
                label="search..."
                v-model="search"
                append-icon="mdi-magnify"
                dense
                width="54"
                color="primary"
                class="float-right"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span v-html="displayType(item.type)"></span>
      </template>
      <template v-slot:[`item.attachment`]="{ item }">
        <a
          :href="item.attachment"
          target="_blank"
          v-if="item.attachment"
          class="text-decoration-none"
        >
          <v-icon color="primary">mdi-attachment</v-icon>
        </a>
        <a
          :href="item.attachment"
          target="_blank"
          v-else
          class="text-decoration-none"
        >
          N/A
        </a>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        {{ toMoneyFormat(item.amount) }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.workflow.approval_groups.length > 0">
          <div v-if="item.workflow.approval_groups">
            <div v-if="item.receiving == 0">
              <a class="grey--text"> Closed </a>
            </div>

            <div v-else-if="item.active == 1">
              <a
                class="info--text"
                v-if="item.workflow.approval_groups.length > item.status"
              >
                Pending
                <span class="font-weight-bold" v-if="item.workflow.approval_groups.length > item.status">{{
                  item.workflow.approval_groups[item.status].title
                }}</span>
                Approval
              </a>
              <a class="success--text" v-else> Approved </a>
            </div>

            <div v-else-if="item.active == 0">
              <a class="warning--text"> Rejected </a>
            </div>

            <div v-else>
              <a class="error--text"> Undefined </a>
            </div>
          </div>
        </div>

        <div v-else>
          <router-link class="red--text text-decoration-none" link :to="href">
            Please select workflow steps
          </router-link>
        </div>
      </template>

      <template v-slot:[`item.workflow`]="{ item }">
        <div v-if="item.workflow.approval_groups.length > 0">
          <a
            v-for="(wkfl, i) in item.workflow.approval_groups"
            :key="i"
          >
            <span v-if="i != 0"> <v-icon>mdi-arrow-right-bold</v-icon> </span>
            <a v-if="i == item.status" class="info--text">{{ wkfl.title }}</a>
            <a v-else class="primary--text">{{ wkfl.title }}</a>
          </a>
        </div>

        <div v-else>
          <router-link class="red--text text-decoration-none" link :to="href">
            Please select workflow steps
          </router-link>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
              <span>
                <v-btn
                  v-if="item.sent_to_eboard_approval == 1"
                  color="grey"
                  class="mx-1"
                  fab
                  x-small
                  dark
                >
                  <v-icon>mdi-alpha-e-circle-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="info"
                  class="mx-1"
                  fab
                  x-small
                  dark
                  @click="sendToEboard(item)"
                >
                  <v-icon>mdi-alpha-e-circle-outline</v-icon>
                </v-btn>
              </span>
            </span>
          </template>
          <span v-if="item.sent_to_eboard_approval == 1"
            >Sent to eBoard for approval</span
          >
          <span v-else>Send to eBoard for approval</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
              <v-btn
                v-if="item.sent_to_eboard_approval == 1"
                color="grey"
                class="mx-1"
                fab
                x-small
                dark
              >
                <v-icon>mdi-skip-next-outline</v-icon> </v-btn
              ><v-btn
                v-else
                color="success"
                class="mx-1"
                fab
                x-small
                dark
                @click="executeApproved(item)"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </span>
          </template>
          <span v-if="item.sent_to_eboard_approval == 1"
            >Needs approval before execute</span
          >
          <span v-else>Create contract</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
              <v-btn
                color="primary"
                class="mx-1"
                fab
                x-small
                dark
                @click="viewItemModal(item)"
              >
                <v-icon>mdi-view-carousel-outline</v-icon>
              </v-btn>
            </span>
          </template>
          <span>Preview</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <div class="text-center">
      <v-dialog v-model="dialog_view" width="800">
        <v-card>
          <v-card-title class="headline text--white lighten-2">
            Preview Requisition
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-text-field
                    v-model="editedItem.title"
                    label="Title"
                    :rules="rules.required"
                    color="grey"
                    dense
                    readonly
                    outlined
                    class="mb-n3"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Name
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="editedItem.workflow_id"
                    :items="workflows"
                    label="Select Workflow"
                    item-text="title"
                    item-value="id"
                    color="grey"
                    dense
                    readonly
                    outlined
                    class="d-flex align-end"
                    :rules="selectRules"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="mt-n4">
                  <span class="d-flex float-right">
                    <v-tooltip top color="info">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="text-capitalize mx-1"
                          :loading="loading"
                          @click="generateReport"
                          elevation="0"
                          fab
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-printer-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Print approval report</span>
                    </v-tooltip>
                  </span>
                  <h3 class="ml-2 my-2 primary--text">Approval History</h3>

                  <v-divider></v-divider>
                  <v-data-table
                    :headers="headers_approvals"
                    :items="approval_history"
                    :items-per-page="5"
                    class="elevation-1"
                  ></v-data-table>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <label>Description</label>
                  <ckeditor
                    class="w-full"
                    tag-name="textarea"
                    ref="cktext"
                    :editor="editor"
                    v-model="editedItem.description"
                    disabled
                    shouldnotGroupWhenFull="true"
                    :config="editorConfig2"
                  ></ckeditor>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-2">
                  <label>Requisition Items</label>

                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">#</th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(requisition_item, i) in requisition_items"
                            :key="i"
                          >
                            <td data-label="No.">{{ i + 1 }}</td>
                            <td>
                              <v-text-field
                                v-model="requisition_item.name"
                                label="Name"
                                :rules="rules.items"
                                color="grey"
                                readonly
                                dense
                                outlined
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Name
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </td>

                            <td>
                              <v-text-field
                                v-model="requisition_item.quantity"
                                label="Quantity"
                                :rules="rules.items"
                                color="grey"
                                readonly
                                dense
                                outlined
                                type="number"
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Quantity
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-file-input
                    v-model="editedItem.attachment_file"
                    color="grey"
                    append-icon="mdi-file-upload"
                    prepend-icon=""
                    outlined
                    disabled
                    dense
                    @change="handleFileUpload"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Attach Document
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_view = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialogSendToSuppliers" width="600">
        <v-card>
          <v-card-title class="headline">
            Request Quotation from suppliers
          </v-card-title>
          <v-divider></v-divider>
          <v-col cols="6" md="6">
            <v-btn
              color="primary "
              class="mx-1"
              fab
              x-small
              dark
              @click="generateReport"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-col>

          <v-form ref="form1" v-model="valid1" lazy-validation>
            <v-col cols="12" md="12" class="mb-n3">
              <v-select
                v-model="editedItem.supplier_group_id"
                :items="supplier_groups"
                label="Select supplier group"
                item-text="title"
                item-value="id"
                color="grey"
                dense
                outlined
                class="d-flex align-end"
                :rules="selectRules"
              ></v-select>
            </v-col>
          </v-form>
          <div id="printable_content">
            <v-card-text>
              <h3>{{ editedItem.title }}</h3>
              <v-divider></v-divider>
              <div
                class="pa-2 bt-3 outlined"
                v-html="editedItem.description"
              ></div>
            </v-card-text>
            <v-divider></v-divider>
            <v-col cols="12" sm="12" md="12" class="my-2">
              <label>Requisition Items</label>

              <template>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(requisition_item, i) in requisition_items"
                        :key="i"
                      >
                        <td data-label="No.">{{ i + 1 }}</td>
                        <td>
                          <v-text-field
                            v-model="requisition_item.name"
                            label="Name"
                            :rules="rules.items"
                            color="grey"
                            dense
                            readonly
                            outlined
                            class="mb-n3"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Name
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                          </v-text-field>
                        </td>

                        <td>
                          <v-text-field
                            v-model="requisition_item.quantity"
                            label="Quantity"
                            :rules="rules.items"
                            color="grey"
                            dense
                            readonly
                            outlined
                            type="number"
                            class="mb-n3"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Quantity
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              dark
              @click="requestQuotation"
              >Request Quotations</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" id="printarea">
        <!-- PDF Content Here -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<style>
.ck-editor__editable {
  min-height: 250px;
}
.in-editor .ck-toolbar {
  display: inline !important;
}
</style>
<script>
import RequisitionService from "../../services/requisition.service";
import QuotationRequestService from "../../services/quotation_request.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import WorkflowService from "../../services/workflow.service";
import SupplierCategoryService from "../../services/supplier_category.service";
import MainStepper from "../../components/MainStepper.vue";

import VueHtml2pdf from "vue-html2pdf";

// import {
//   Heading,
//   Bold,
//   Italic,
//   Strike,
//   Underline,
//   Code,
//   Paragraph,
//   BulletList,
//   OrderedList,
//   ListItem,
//   Link,
//   Blockquote,
//   HardBreak,
//   HorizontalRule,
//   History,
// } from "tiptap-vuetify";

export default {
  components: { VueHtml2pdf, MainStepper },
  data: () => ({
    name: "AuthorityApprovals",
    workflows: [],
    supplier_groups: [],
    selectRules: [(v) => !!v || "Select is required"],
    dialog_view: false,
    dialogSendToSuppliers: false,
    editor: ClassicEditor,

    headers_approvals: [
      { text: "Approver", value: "approver" },
      { text: "Action", value: "action" },
      { text: "Comment", value: "comment" },
      { text: "Condition", value: "condition" },
      { text: "Date", value: "date" },
    ],
    approval_history: [
      {
        approver: "Samson Anami",
        action: "Approved",
        comment: "Approved",
        condition: "Financials are provided",
        date: "02-03-2021",
      },
      {
        approver: "Mike Orenda",
        action: "Reject",
        comment: "Provide the KYC documents",
        condition: "Technical are provided",
        date: "02-03-2021",
      },
      {
        approver: "Jelly bean",
        action: "Comment",
        comment: "Provide the KYC documents",
        condition: "Technical are provided",
        date: "02-03-2021",
      },
    ],

    editorConfig: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    editorConfig2: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      readOnly: true,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    loading: true,
    items: [],
    requisition_items: [],
    search: "",
    editedIndex: -1,
    editedItem: {
      amount: 0,
      attachment: "",
      attachment_file: [],
      requisition_items: [],
    },
    defaultItem: {
      amount: 0,
      attachment: "",
      attachment_file: [],
      requisition_items: [],
    },
    remember: 0,
    href: "workflows",
    timeout: 2000,
    dialog: false,
    valid: true,
    valid1: true,
    // declare extensions you want to use
    // extensions: [
    //   History,
    //   Blockquote,
    //   Link,
    //   Underline,
    //   Strike,
    //   Italic,
    //   ListItem,
    //   BulletList,
    //   OrderedList,
    //   [
    //     Heading,
    //     {
    //       options: {
    //         levels: [1, 2, 3],
    //       },
    //     },
    //   ],
    //   Bold,
    //   Code,
    //   HorizontalRule,
    //   Paragraph,
    //   HardBreak,
    // ],
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
      items: [(v) => !!v || "Field is required"],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Workflow Steps",
        value: "workflow",
      },
      {
        text: "Attachment",
        value: "attachment",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Amount(USD)",
        value: "amount",
      },
      {
        text: "Actions",
        align: "right",
        value: "actions",
        sortable: false,
        width: "15%",
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomerequired_documents() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomerequired_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.index();
    this.getAllWorkflows();
    this.getSupplierCategories();
  },
  methods: {
    gotToMyApprovals() {
      this.$router.push("/requisition-approval");
    },
    goToNeedIdentification() {
      this.$router.push("/need-identification");
    },
    goToContracts() {
      this.$router.push("/contract-initiation");
    },
    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    executeApproved(item) {
      localStorage.setItem('requisition', JSON.stringify(item))
      this.$router.push("/contract-initiation/" + item.id);
    },
    displayType(type) {
      if (type == 1) {
        return "<v-chip color='orange'>Capex</v-chip>";
      } else {
        return "<v-chip color='primary'>Opex</v-chip>";
      }
    },
    toMoneyFormat(number) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(number);
    },
    getSupplierCategories() {
      return SupplierCategoryService.index().then(
        (response) => {
          if (response.status == 200) {
            this.supplier_groups = response.data.data;
            this.loading = false;
          } else {
            this.supplier_groups = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.supplier_groups = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    addNewItem() {
      this.requisition_items.push({ name: "", quantity: "" });
    },
    addNewEditedItem() {
      this.requisition_items.push({ name: "", quantity: "", edited: 1 });
    },
    generateReport() {
      var printable_content = document.getElementById("printable_content");
      var pdf = document.getElementById("printarea");
      pdf.innerHTML = printable_content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },
    getAllWorkflows() {
      return WorkflowService.index().then(
        (response) => {
          if (response.status == 200) {
            this.workflows = response.data.data;
          } else {
            this.roles = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.roles = [];
          console.log(error);
        }
      );
    },
    trimText(text, length) {
      text.substring(0, length);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialog = true;
    },
    viewItemModal(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialog_view = true;
    },
    sendItemToSupplier(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialogSendToSuppliers = true;
    },

    // search
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    index() {
      return RequisitionService.index().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    viewItem(item) {
      this.$router.push("/suppliers-view/" + item.id);
    },
    sendToEboard(item) {
      item.approval_name = item.title;
      item.applicant_name = this.user.name;
      const index = this.items.indexOf(item);
      var sendConfirm = confirm("Confirm sending item for eBoard approval?");
      if (sendConfirm) {
        RequisitionService.sendToEboard(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
              this.index();
            } else {
              this.$store.dispatch("alert/error", "Error sending approval");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser) {
        RequisitionService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
            } else {
              this.$store.dispatch("alert/error", "Error deleting document");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },
    deleteNewRequisitionItem(item) {
      const index = this.requisition_items.indexOf(item);
      var deleteReqItem = confirm("Are you sure you want to delete this item?");
      if (deleteReqItem) {
        this.requisition_items.splice(index, 1);
      }
    },
    deleteRequisitionItem(item) {
      const index = this.requisition_items.indexOf(item);
      var deleteReqItem = null;
      if (item.edited == 1) {
        deleteReqItem = confirm("Are you sure you want to delete this item?");
        if (deleteReqItem) {
          this.requisition_items.splice(index, 1);
        }
      } else {
        deleteReqItem = confirm("Are you sure you want to delete this item?");
        if (deleteReqItem) {
          RequisitionService.deleteItem(item).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.requisition_items.splice(index, 1);
              } else {
                this.$store.dispatch("alert/error", "Error deleting document");
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              } else if (error.response.status == 500) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              } else if (error.response.status == 502) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              } else if (error.response.status == 401) {
                this.handleLogout();
              } else {
                this.$store.dispatch("alert/error", error.response.statusText);
              }
            }
          );
        }
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
    },

    save() {
      this.loading = true;
      this.$refs.form.validate();
      this.editedItem.requisition_items = this.requisition_items;

      if (this.valid) {
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          try {
            if (this.editedItem.required_documents[0].id) {
              this.editedItem.required_documents =
                this.editedItem.required_document_ids;
            }
          } catch (error) {
            console.log(error);
          }
          console.log(this.editedItem);
          RequisitionService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          RequisitionService.store(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
        this.index();
      }
    },
    requestQuotation() {
      this.loading = true;
      this.$refs.form1.validate();
      if (this.valid) {
        this.editedItem.requisition_id = this.editedItem.id;
        this.editedItem.user_id = this.user.id;
        QuotationRequestService.store(this.editedItem).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              this.$store.dispatch(
                "alert/success",
                "Request sent successfully"
              );
              this.loading = false;
              this.dialogSendToSuppliers = false;
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.dialogSendToSuppliers = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.dialogSendToSuppliers = false;
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.dialogSendToSuppliers = false;
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
